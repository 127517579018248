/**
 * Created by johnny on 18/08/16
 */

export const FormInputTypes = {
  TEXT: 'text',
  LABEL: 'label',
  SELECT: 'select',
  PASSWORD: 'password',
  TEXT_HIDDEN: 'hidden',
  TEXT_AREA: 'textArea',
  TEXT_AUTOCOMPLETE: 'textAutocomplete',
  DYNAMIC_NUMBER: 'dynamicNumber',
  DATE_PICKER: 'datePicker',
  DEFAULT_IMAGE_URL: 'https://dummyimage.com/240X180/e4e6ed/7b7b80.jpg&text=No+Image+Found'
}

export const InputTypes = {
  TEXT: 'TEXT',
  LABEL: 'LABEL',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  PASSWORD: 'PASSWORD',
  TEXT_HIDDEN: 'HIDDEN',
  TEXT_AREA: 'TEXTAREA',
  TEXT_AUTOCOMPLETE: 'textAutocomplete',
  DYNAMIC_NUMBER: 'DYNAMIC_NUMBER',
  NUMBER: 'NUMBER',
  DATE_PICKER: 'DATE_PICKER',
  PHONE_MASK: 'PHONE_MASK',
  IMAGE: 'IMAGE',
  RADIO_BUTTON: 'RADIO_BUTTON',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  STATE: 'STATE',
  PARAGRAPH: 'PARAGRAPH',
  DEFAULT_IMAGE_URL: 'https://dummyimage.com/240X180/e4e6ed/7b7b80.jpg&text=No+Image+Found',
  PERCENT: 'PERCENT',
  PRICE: 'PRICE',
  DATE: 'DATE',
  LIST: 'LIST',
  SINGLE_TEXT: 'SINGLE_TEXT'
}

export const formStatus = [
  {
    id: 'acquired',
    name: 'Acquired',
    value: 'Acquired',
    selected: false
  },
  {
    id: 'inspect',
    name: 'Inspect',
    value: 'Inspect/Repair',
    selected: false
  },
  {
    id: 'hold',
    name: 'Hold',
    value: 'Hold',
    selected: false
  },
  {
    id: 'comingsoon',
    name: 'Coming Soon',
    value: 'Coming Soon',
    selected: false
  },
  {
    id: 'flr',
    name: 'Front Line Ready',
    value: 'Front Line Ready',
    selected: false
  },
  {
    id: 'sold',
    name: 'Sold',
    value: 'Sold',
    selected: false
  },
  {
    id: 'archived',
    name: 'Archived',
    value: 'Archived',
    selected: false
  }
]

export const SORT_ICON_DEFAULT = 'fa fa-sort'
export const FORM_CUSTOMER = 'formCustomerNew'
export const FORM_CUSTOMER_REVIEW = 'newCustomerReviewForm';
export const FORM_CAR_NEW = 'formCarNew'
export const INDIVIDUAL_CREDIT_DISPLAY_VALUE = 'Individual Credit'
export const INDIVIDUAL_CREDIT_NAME = 'individualCredit'
export const ATTRIBUTE_CUSTOMER_DRIVER_LICENSE_STATE = 'customer.driverLicenseState'
export const ATTRIBUTE_CUSTOMER_MIDDLE_NAME = 'customer.middleName'
export const ATTRIBUTE_CUSTOMER_DOBMDY = 'customer.dobmdy'
export const ATTRIBUTE_CUSTOMER_CELLPHONE = 'customer.cellPhone'
export const ATTRIBUTE_CUSTOMER_SECOND_PHONE = 'customer.secondPhone'
export const ATTRIBUTE_CUSTOMER_EMAIL = 'customer.email'
export const ATTRIBUTE_CUSTOMER_ADDRESS = 'customer.address'
export const ATTRIBUTE_CUSTOMER_APT = 'customer.apt'
export const ATTRIBUTE_CUSTOMER_CITY = 'customer.city'
export const ATTRIBUTE_CUSTOMER_ZIP_CODE = 'customer.zipCode'
export const ATTRIBUTE_CUSTOMER_MONTHLY_RENT = 'customer.monthlyRent'
export const ATTRIBUTE_CUSTOMER_ADDRESS_PREVIOUS = 'customer.addressPrevious'
export const ATTRIBUTE_CUSTOMER_APT_PREVIOUS = 'customer.aptPrevious'
export const ATTRIBUTE_CUSTOMER_CITY_PREVIOUS = 'customer.cityPrevious'
export const ATTRIBUTE_CUSTOMER_STATE_PREVIOUS = 'customer.statePrevious'
export const ATTRIBUTE_CUSTOMER_ZIP_CODE_PREVIOUS = 'customer.zipCodePrevious'
export const ATTRIBUTE_CUSTOMER_HOUSING_STATUS_PREVIOUS = 'customer.housingStatusPrevious'
export const ATTRIBUTE_CUSTOMER_MONTHLY_RENT_PREVIOUS = 'customer.monthlyRentPrevious'
export const ATTRIBUTE_CUSTOMER_ADDRESS_ANOTHER = 'customer.addressAnother'
export const ATTRIBUTE_CUSTOMER_APT_ANOTHER = 'customer.aptAnother'
export const ATTRIBUTE_CUSTOMER_CITY_ANOTHER = 'customer.cityAnother'
export const ATTRIBUTE_CUSTOMER_STATE_ANOTHER = 'customer.stateAnother'
export const ATTRIBUTE_CUSTOMER_ZIP_CODE_ANOTHER = 'customer.zipCodeAnother'
export const ATTRIBUTE_CUSTOMER_HOUSING_STATUS_ANOTHER = 'customer.housingStatusAnother'
export const ATTRIBUTE_CUSTOMER_YEAR_ANOTHER = 'customer.yearAnother'
export const ATTRIBUTE_CUSTOMER_MONTH_ANOTHER = 'customer.monthAnother'
export const ATTRIBUTE_CUSTOMER_MONTHLY_RENT_ANOTHER = 'customer.monthlyRentAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_NAME = 'customer.employerName'
export const ATTRIBUTE_CUSTOMER_JOB_TITLE = 'customer.jobTitle'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE = 'customer.employerCellPhone'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS = 'customer.employerAddress'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CITY = 'customer.employerCity'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE = 'customer.employerZipCode'
export const ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME = 'customer.monthlyInCome'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_PREVIOUS = 'customer.employerNamePrevious'
export const ATTRIBUTE_CUSTOMER_JOB_TITLE_PREVIOUS = 'customer.jobTitlePrevious'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_PREVIOUS = 'customer.employerCellPhonePrevious'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_PREVIOUS = 'customer.employerAddressPrevious'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_PREVIOUS = 'customer.employerCityPrevious'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_PREVIOUS = 'customer.employerStatePrevious'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_PREVIOUS = 'customer.employerZipCodePrevious'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_PREVIOUS = 'customer.employmentStatusPrevious'
export const ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_PREVIOUS = 'customer.monthlyInComePrevious'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_ANOTHER = 'customer.employerNameAnother'
export const ATTRIBUTE_CUSTOMER_JOB_TITLE_ANOTHER = 'customer.jobTitleAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_ANOTHER = 'customer.employerCellPhoneAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_ANOTHER = 'customer.employerAddressAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_ANOTHER = 'customer.employerCityAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_ANOTHER = 'customer.employerStateAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_ANOTHER = 'customer.employerZipCodeAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_ANOTHER = 'customer.employmentStatusAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_ANOTHER = 'customer.employerYearAnother'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_ANOTHER = 'customer.employerMonthAnother'
export const ATTRIBUTE_CUSTOMER_MONTHLY_IN_COME_ANOTHER = 'customer.monthlyInComeAnother'
export const ATTRIBUTE_CUSTOMER_OTHER_INCOME_SOURCE = 'customer.otherIncomeSource'
export const ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SOURCE_OTHER = 'customer.monthlyIncomeSourceOther'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_SECOND = 'customer.employerNameSecond'
export const ATTRIBUTE_CUSTOMER_JOB_TITLE_SECOND = 'customer.jobTitleSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_SECOND = 'customer.employerCellPhoneSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_SECOND = 'customer.employerAddressSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_SECOND = 'customer.employerCitySecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_SECOND = 'customer.employerStateSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_SECOND = 'customer.employerZipCodeSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND = 'customer.employmentStatusSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND = 'customer.employerYearSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND = 'customer.employerMonthSecond'
export const ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SECOND = 'customer.monthlyInComeSecond'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_THIRD = 'customer.employerNameThird'
export const ATTRIBUTE_CUSTOMER_JOB_TITLE_THIRD = 'customer.jobTitleThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_THIRD = 'customer.employerCellPhoneThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_THIRD = 'customer.employerAddressThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_THIRD = 'customer.employerCityThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_THIRD = 'customer.employerStateThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_THIRD = 'customer.employerZipCodeThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD = 'customer.employmentStatusThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD = 'customer.employerYearThird'
export const ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD = 'customer.employerMonthThird'
export const ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_THIRD = 'customer.monthlyInComeThird'

export const optionHours = ['Closed', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00',
  '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00']

export const OptionOpeningHours24 = [
  {id: '08:00', displayValue: '08:00'},
  {id: '08:30', displayValue: '08:30'},
  {id: '09:00', displayValue: '09:00'},
  {id: '09:30', displayValue: '09:30'},
  {id: '10:00', displayValue: '10:00'},
  {id: '10:30', displayValue: '10:30'},
  {id: '11:00', displayValue: '11:00'},
  {id: '11:30', displayValue: '11:30'},
  {id: '12:00', displayValue: '12:00'},
  {id: '12:30', displayValue: '12:30'},
  {id: '13:00', displayValue: '13:00'},
  {id: '13:30', displayValue: '13:30'},
  {id: '14:00', displayValue: '14:00'},
  {id: '14:30', displayValue: '14:30'},
  {id: '15:00', displayValue: '15:00'},
  {id: '15:30', displayValue: '15:30'},
  {id: '16:00', displayValue: '16:00'},
  {id: '16:30', displayValue: '16:30'},
  {id: '17:00', displayValue: '17:00'},
  {id: '17:30', displayValue: '17:30'},
  {id: '18:00', displayValue: '18:00'},
  {id: '18:30', displayValue: '18:30'},
  {id: '19:00', displayValue: '19:00'},
]

export const OptionOpeningHours = [
  {id: '08:00', displayValue: '08:00 AM'},
  {id: '08:30', displayValue: '08:30 AM'},
  {id: '09:00', displayValue: '09:00 AM'},
  {id: '09:30', displayValue: '09:30 AM'},
  {id: '10:00', displayValue: '10:00 AM'},
  {id: '10:30', displayValue: '10:30 AM'},
  {id: '11:00', displayValue: '11:00 AM'},
  {id: '11:30', displayValue: '11:30 AM'},
  {id: '12:00', displayValue: '12:00 PM'},
  {id: '12:30', displayValue: '12:30 PM'},
  {id: '13:00', displayValue: '01:00 PM'},
  {id: '13:30', displayValue: '01:30 PM'},
  {id: '14:00', displayValue: '02:00 PM'},
  {id: '14:30', displayValue: '02:30 PM'},
  {id: '15:00', displayValue: '03:00 PM'},
  {id: '15:30', displayValue: '03:30 PM'},
  {id: '16:00', displayValue: '04:00 PM'},
  {id: '16:30', displayValue: '04:30 PM'},
  {id: '17:00', displayValue: '05:00 PM'},
  {id: '17:30', displayValue: '05:30 PM'},
  {id: '18:00', displayValue: '06:00 PM'},
  {id: '18:30', displayValue: '06:30 PM'},
  {id: '19:00', displayValue: '07:00 PM'},
]
export const JOIN_CREDIT_NAME = 'joinCredit'
export const JOIN_CREDIT_DISPLAY_VALUE = 'Joint Credit'

export const CLEAR_ALL = 'Clear All'

export const ALPHABETICAL_ORDER = {
  ASC: 'A-Z',
  DESC: 'Z-A',
}

export const INPUT_TYPES = ['TEXT', 'LABEL', 'SELECT', 'PASSWORD', 'HIDDEN', 'TEXTAREA', 'TEXTAUTOCOMPLETE', 'DYNAMIC_NUMBER', 'DATE_PICKER', 'RADIO', 'CHECKBOX', 'MULTI_SELECT']

export const MESSAGE_ACTIONS = [
  { id: 'delete', value: 'Delete' },
  { id: 'read', value: 'Read' },
  { id: 'unRead', value: 'Unread' }
]

export const RESPONSE_MESSAGE_ACTIONS = [{ id: 'delete', value: 'Delete' }]

export const APPLICANTS_ACTIONS = [
  { id: 'new', value: 'New' },
  { id: 'inProgress', value: 'In Progress' },
  { id: 'processed', value: 'Processed' },
  { id: 'bad', value: 'Bad Lead' },
  { id: 'review', value: 'Review Later' },
  { id: 'deleted', value: 'Delete' }
]

export const MESSAGE_UNDELETE_ACTIONS = ['Undelete']
export const MESSAGE_UNDELETE_ACTIONS_FINANCE = [{ id: 'undelete', value: 'Undelete' }]
export const SHOW_MESSAGES = [
  { id: 'all', value: 'All Messages' },
  { id: 'unRead', value: 'Unread Messages' },
  { id: 'read', value: 'Read Messages' },
  { id: 'delete', value: 'Delete Messages' }
]

export const SHOW_RESPONSE_MESSAGE = [
  { id: 'allMessages', value: 'Sent Messages' },
  { id: 'deleteMessages', value: 'Deleted Messages' }
]

export const SORT_MESSAGES = [
  { id: 'newest', value: 'Newest - Oldest' },
  { id: 'old', value: 'Oldest - Newest' }
]

export const SHOW_APPLICANTS = [
  { id: 'all', value: 'All Applicants' },
  { id: 'new', value: 'New Applicants' },
  { id: 'inProgress', value: 'In Progress Applicants' },
  { id: 'processed', value: 'Processed Applicants' },
  { id: 'bad', value: 'Bad Lead Applicants' },
  { id: 'review', value: 'Review Later Applicants' },
  { id: 'deleted', value: 'Deleted Applicants' }
]

export const SORT_APPLICANTS = [
  { id: 'clear', value: CLEAR_ALL },
  { id: 'newest', value: 'Newest Applicants' },
  { id: 'old', value: 'Oldest Applicants' }
]

export const MESSAGE_REPLY = 'reply'

export const ENTITY_TYPES = [
  { name: 'CAR', value: 'CAR' },
  { name: 'LEAD', value: 'LEAD' },
  { name: 'CUSTOMER', value: 'CUSTOMER' },
  { name: 'USER', value: 'USER' },
  { name: 'SALE', value: 'SALE' }
]

export const RENDER_TYPES = [
  { name: 'SUBMIT', value: 'SUBMIT' },
  { name: 'IN LINE', value: 'IN LINE' }
]
export const FORM_TYPES = [
  { name: 'USER', value: 'USER' },
  { name: 'SYSTEM', value: 'SYSTEM' },
]

export const NUMBER_PAGES_SHOWN = 10

export const STATUS_SOLD = 'SOLD'

export const VISIBILITY_HIDE = 'Hide'

export const VISIBILITY_SHOW = 'Show'

export const INTERIOR_COLOR_CODE = 'car.interiorColor'
export const EXTERIOR_COLOR_CODE = 'car.exteriorColor'

export const STATUS_DELETE = 'delete'
export const UNDO_DELETE = 'restore'

export const CARS_BY_PAGE = [10, 20, 28, 36, 44, 52, 60]

export const USERS_BY_PAGE = [10, 20, 30]
export const CUSTOMERS_BY_PAGE = [10, 15, 20]
export const SALES_BY_PAGE = [10, 16, 20, 24, 28, 32, 36]
export const DEALERS_BY_PAGE = [16, 20, 24, 28, 32, 36]
export const SUPERUSERS_BY_PAGE = [10, 16, 20, 24, 28, 32, 36]
export const CUSTOMERS_BY_PAGE_BY_DEFAULT = 10
export const DEALERS_BY_PAGE_BY_DEFAULT = 16
export const SUPERUSERS_BY_PAGE_BY_DEFAULT = 10
export const USERS_BY_PAGE_BY_DEFAULT = 10
export const SALES_BY_PAGE_BY_DEFAULT = 10
export const ROWS_IMPORT_BY_PAGE_BY_DEFAULT = 50

export const ATTRIBUTE_CREDIT_SCORE = 'customer.creditScore'
export const ATTRIBUTE_TYPE_CREDIT = 'customer.typeCredit'
export const ATTRIBUTE_TYPE_ID = 'customer.typeId'

export const SIDEBAR_WIDTH_EXPANDED = 200
export const SIDEBAR_WIDTH_UNEXPANDED = 50

export const ATTRIBUTE_CODE_SUGGESTED_PRICE = 'suggestedPrice'

export const FORM_APPLICANT_CODE_ADMIN = 'formAdminFinance'

export const ABOUT_US_ICON_NAMES = [
  ['ic_style.svg', 'ic_email.svg', 'ic_favorite_border.svg', 'ic_check_circle2.svg', 'ic_store_mall_directory.svg'],
  ['ic_verified_user.svg', 'ic_hearing.svg', 'ic_flash_on.svg', 'ic_face.svg', 'ic_perm_phone_msg.svg'],
  ['ic_star.svg', 'ic_date_range.svg', 'ic_record_voice_over.svg', 'ic_thumb_up.svg', 'ic_thumb_down.svg'],
  ['ic_whatshot.svg', 'ic_public.svg', 'ic_monetization.svg', 'ic_group.svg', 'ic_directions_car.svg'],
  ['ic_edit_pencil.svg', 'ic_directions_car.svg', 'ic_directions_car.svg', 'ic_description.svg']
]

export const FOOTER_ICON_NAMES = [
  ['ic_text_fields.svg', 'ic_phone.svg', 'ic_email.svg', 'ic_link.svg']
]

export const FACEBOOK_PAGE_ID_CODE = 'marketing.facebook.pageId'
export const FACEBOOK_USER_ID_CODE = 'marketing.facebook.userId'
export const FACEBOOK_TOKEN_CODE = 'marketing.facebook.token'
export const GOOGLE_REVIEW_URL = 'marketing.google.review.url'
export const INSTAGRAM_USER_ID_CODE = 'marketing.instagram.userId'
export const CARFAX_LINK = 'marketing.carfax.link'

export const TASK_STATUS_TODO = 'todo'
export const TASK_STATUS_DONE = 'done'
export const TASK_STATUS_COMPLETE = 'complete'
export const TASK_PLACE_HOLDER = 'Search Task, Date, VIN number, Person assigned'

export const carStatus = {
  SOLD: 'Sold',
  ARCHIVED: 'Archived'
}

export const TASK_TYPE = {
  NORMAL: 'normal',
  REPAIR: 'repair'
}

export const SUGGESTED_PRICE_REQUIRED = {
  type: 'required',
  message: 'Suggested Price is required'
}

export const CAR_STATUS_CODE_FRONT_LINE_READY = 'flr'
export const CAR_STATUS_CODE_INSPECT_REPAIR = 'inspect'
export const CAR_STATUS_CODE_ACQUIRED = 'acquired'
export const CAR_STATUS_CODE_HOLD = 'hold'
export const CAR_STATUS_CODE_COMING_SOON = 'comingsoon'

export const CAR_STATUS_FRONT_LINE_READY = 'Front Line Ready'
export const CAR_STATUS_INSPECT_REPAIR = 'Inspect/Repair'
export const CAR_STATUS_ACQUIRED = 'Acquired'
export const CAR_STATUS_HOLD = 'Hold'
export const CAR_STATUS_SOLD = 'Sold'
export const CAR_STATUS_COMING_SOON = 'Coming Soon'
export const CAR_STATUS_ARCHIVED = 'Archived'

export const CAR_SECTION_CAR_PRICING = 'carPricing'
export const CAR_SECTION_INFORMATION = 'carInformation'

export const DEFAULT_TASK = {
  typeTask: 'repair',
  priority: 'low',
  title: '',
  status: 'todo'
}

export const COST_REPAIR_PART = 'parts'
export const COST_REPAIR_LABOR = 'labor'

export const REPAIR_TYPE_FILE_RECEIPT = 'receipt'

export const DEFAULT_REPAIR = {
  priority: 'low',
  description: '',
  assignedTo: '',
  status: 'todo',
  carServices: '[]',
  carId: ''
}

export const LOCATION_STATUS_ACTIVE = 'active'
export const LOCATION_STATUS_INACTIVE = 'inactive'
export const LOCATION_STATUS_DELETED = 'deleted'

export const TEMPLATE_CODE_SALE_AGREEMENT = 'saleAgreementContract'
export const TEMPLATE_CODE_TAX_EXEMPTION = 'taxExemptionDocument'

export const WIDTH_INVENTORY_TABLET = 1360

export const ATTRIBUTE_CODE_MAKE_ID = 'makeId'
export const ATTRIBUTE_CODE_MODEL_ID = 'modelId'
export const ATTRIBUTE_CODE_CAR_FUEL = 'car.fuel'
export const ATTRIBUTE_CODE_CAR_BODY = 'car.body'
export const ATTRIBUTE_CODE_CAR_INTERIOR_COLOR = 'car.interiorColor'
export const ATTRIBUTE_CODE_CAR_EXTERIOR_COLOR = 'car.exteriorColor'
export const ATTRIBUTE_CODE_CAR_CYLINDER = 'car.cylinder'
export const ATTRIBUTE_CODE_CAR_YEAR = 'year'
export const ATTRIBUTE_CODE_CAR_TRIM = 'car.trim'
export const ATTRIBUTE_CODE_CAR_DOOR = 'car.numberDoor'
export const ATTRIBUTE_CODE_CAR_TRANSMISSION = 'car.transmission'
export const ATTRIBUTE_CODE_CAR_DRIVE = 'car.drive'
export const ATTRIBUTE_CODE_CAR_TITLE = 'car.title'
export const ATTRIBUTE_CODE_CAR_CONDITION = 'car.condition'
export const ATTRIBUTE_CODE_CAR_MARKETING_FACEBOOK = 'car.marketingFacebook'
export const ATTRIBUTE_CODE_CAR_MARKETING_INSTAGRAM = 'car.marketingInstagram'
export const ATTRIBUTE_CODE_CAR_MARKETING_FBMARKETPLACE = 'car.marketingFacebookMarketplace'

export const formCarApi = {
  MAKE: 'Make',
  MODEL: 'Model',
  YEAR: 'ModelYear',
  TRIM: 'Trim',
  DOORS: 'Doors',
  CYLINDERS: 'EngineCylinders',
  DRIVE: 'DriveType',
  TRANSMISSION: 'TransmissionStyle',
  BODY_STYLE: 'BodyClass',
  FUEL: 'FuelTypePrimary',
  ERROR_CODE: 'ErrorCode',
}

export const VIEW_SALE = 'sale'

export const MESSAGE_CAR_ALREADY_SALE = 'This car is already in the following sale process(es).'

export const CAR_STATUS_PUBLIC_CODE = 'car.status.public'
export const INVENTORY_DELETE_PASSWORD_CODE = 'inventory.delete.password'

export const carStatusColors = [
  { id: 'inspect', value: '#2194F1' },
  { id: 'hold', value: '#005294' },
  { id: 'flr', value: '#4AD991' },
  { id: 'sold', value: '#FF6565' },
  { id: 'acquired', value: '#AC7F62' },
  { id: 'comingsoon', value: '#F3A200' },
  { id: 'archived', value: '#787878' }
]

export const VIEW_INVENTORY = 'inventory'

export const FIELD_FILTERS_STORAGE = 'inventoryTableFilters'
export const SALE_FILE_DOES_NOT_EXIST = 208

export const MESSAGE_WITHOUT_FINANCE = 'This customer doesn\'t have finance applications yet.'
export const MESSAGE_WITHOUT_SALES_PURCHASED = 'This customer doesn\'t have purchased cars or sales in process yet.'

export const TAB_PAYMENT = 'payment'
export const TAB_FINANCE = 'finance'
export const TAB_CASH = 'cash'
export const TAB_AGREEMENT = 'agreement'
export const TAB_FILES = 'files'
export const TAB_TAX_EXEMPTION = 'taxExemption'
export const TAB_CHECKLIST = 'checklist'

export const SALE_CANCEL_REASON = 'sales.cancel.reasons'

export const MESSAGE_SAVE_CHANGES = 'Do you want to save the changes?'

export const DEALER_STATUS_ACTIVE = true
export const DEALER_STATUS_INACTIVE = false

export const DEALER_ICON = 'dealerIcon'
export const TAB_DEALER = 'dealer'
export const TAB_SUPER_ADMIN = 'superAdmin'
export const TAB_NEW_DEALER_TIER = 'tier'
export const TAB_NEW_DEALER_PAYMENT_INFO = 'paymentInfo'
export const FORM_TYPE_SYSTEM = 'SYSTEM'
export const FORM_TYPE_USER = 'USER'

export const MESSAGE_WITHOUT_ACCOUNT_OWNERS = 'This dealer doesn\'t have account owners yet.'
export const MESSAGE_WITHOUT_FILE_TEMPLATES = 'This dealer doesn\'t have file templates yet.'
export const MESSAGE_WITHOUT_INVOICES = 'This dealer doesn\'t have invoices yet.'

export const USER_ID_NOT_ASSIGNED = 'notAssigned'
export const USER_NAME_NOT_ASSIGNED = 'Not assigned'
export const ERROR_DUPLICATE_CODE = 102

export const DOMAIN_URL_KEY = 'domainUrl'

export const DUPLICATE_FIELDS = {
  NAME: 'name',
  DOMAIN_URL_KEY: 'domainUrl',
  EMAIL: 'email',
}

export const TAB_FOR_DUPLICATE_FIELDS = {
  NAME: TAB_DEALER,
  DOMAIN_URL_KEY: TAB_DEALER,
  EMAIL: TAB_SUPER_ADMIN,
}

export const colors = {
  GREEN: '#4AD991',
  BLUE: '#005294',
  RED: '#FF6565',
  ORANGE: '#FFC06A',
  GRAY: '#787878',
  LIGHT_BLUE: '#2194F1',
  WHITE: '#FFFFFF',
  LIGHT_GREEN: '#71D6A3',
  DARK_GREEN: '#0DB762',
  YELLOW: '#FFDE00'
}

export const ratingScore = {
  NO_CREDIT: 'Credit Rating: No Credit',
  BAD_CREDIT: 'Credit Rating: Bad',
  GOOD_CREDIT: 'Credit Rating: Good',
  EXCELLENT_CREDIT: 'Credit Rating: Excellent'
}

export const saleStatus = {
  STARTED: 'started',
  IN_PROGRESS: 'inProgress',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed'
}

export const saleStatusValue = {
  [saleStatus.STARTED]: 'Started',
  [saleStatus.IN_PROGRESS]: 'In progress',
  [saleStatus.CANCELLED]: 'Canceled',
  [saleStatus.COMPLETED]: 'Completed',
}

export const SORT_ASC = 'sortAsc'
export const SORT_DESC = 'sortDesc'

export const optionsOrder = [
  { id: 'clear', displayValue: CLEAR_ALL },
  { id: SORT_ASC, displayValue: ALPHABETICAL_ORDER.ASC },
  { id: SORT_DESC, displayValue: ALPHABETICAL_ORDER.DESC },
]

export const financeStatus = {
  NEW: 'new',
  IN_PROGRESS: 'inProgress',
  PROCESSED: 'processed',
  BAD: 'bad',
  REVIEW: 'review',
  DELETED: 'deleted',
  APPROVED: 'approved'
}

export const financeStatusValue = {
  [financeStatus.NEW]: 'New',
  [financeStatus.IN_PROGRESS]: 'In Progress',
  [financeStatus.PROCESSED]: 'Processed',
  [financeStatus.BAD]: 'Bad Lead',
  [financeStatus.REVIEW]: 'Review Later',
  [financeStatus.DELETED]: 'Deleted',
  [financeStatus.APPROVED]: 'Approved'
}

export const MODULE_USER = 'user'
export const MODULE_VEHICLE = 'vehicle'
export const MODULE_COST = 'repair cost'

export const FILTER_TYPE_TASK = [
  {
    label: 'Active tasks',
    value: 'active',
  },
  {
    label: 'Archived tasks',
    value: 'archived',
  },
  {
    label: 'Removed tasks',
    value: 'removed'
  }
]

export const FILE_TEMPLATE_BUYER_AGREEMENT_CODE = 'buyerAgreement'
export const FILE_TEMPLATE_TITLE_AGREEMENT_CODE = 'titleAgreement'
export const FILE_TEMPLATE_TAX_EXEMPTION_CODE = 'taxExemption'
export const FORM_SALE_BUYER_AGREEMENT = 'purchaseAgreementForm'
export const FORM_SALE_FINANCE_APPLICATION = 'financeApplication'
export const FILE_TEMPLATE_BUYER_GUIDE_CODE = 'buyerGuide'
export const FORM_SALE_TITLE_AGREEMENT = 'titleAgreementForm'
export const FORM_BUYER_GUIDE = 'buyerGuideForm'

export const FILE_TEMPLATE_RETAIL_INSTALLMENT_CONTRACT_CODE = 'retailInstallmentContract'
export const FORM_RETAIL_INSTALLMENT_CONTRACT = 'retailInstallmentContractForm'
export const CODE_ODOMETER_CONTRACT = 'odometerContract'
export const CODE_CERTIFIED_OF_INSPECTION = 'certifiedOfInspection'
export const CODE_AGREEMENT_TO_PROVIDE_INSURANCE = 'agreementToProvideInsurance'
export const CODE_NOTICE_TO_COSIGNER = 'noticeToCosigner'
export const CODE_REFERENCE_PROVIDER = 'referenceProvider'
export const CODE_AUTHORIZATION_FOR_PAYOFF = 'authorizationForPayoff'
export const CODE_WHOLESALE_DEALER = 'wholesaleDealer'
export const CODE_STATEMENT_OF_ACCEPTANCE = 'statementOfAcceptance'
export const CODE_TRANSACTION_DISCLOSURE_FORM = 'transactionDisclosureForm'
export const CODE_NOTICE_OF_SALVAGE_OR_BRANDED_TITLE = 'noticeOfSalvageVehicleOrBrandedTitle'

export const FIELD_VALIDATION = 'validation'

export const UPDATE_ACTION = 'update'
export const COPY_ACTION = 'copy'

export const DUPLICATE_FILE_TEMPLATE_CODE = 'A file template with that code already exists and is active. Please change the status of the other template.'

export const typeAttribute = {
  DATE: 'date',
  PARAGRAPH: 'paragraph',
  PASSWORD: 'password',
  SINGLE_TEXT: 'single_text',
  TEXT_AREA: 'text_area',
  DYNAMIC_NUMBER: 'dynamic_number',
  SELECT: 'select',
  MULTI_SELECT: 'multi_select',
  CHECK_BOX: 'checkbox',
  LIST: 'list'
}

export const ENTITY_TYPE_CUSTOMER = 'CUSTOMER'
export const ATTRIBUTE_CUSTOMER_STATE = 'customer.state'
export const ATTRIBUTE_CUSTOMER_FIRST_NAME = 'firstName'
export const ATTRIBUTE_CUSTOMER_LAST_NAME = 'lastName'
export const CAR_TOTAL_COST = 'car.totalCost'
export const PURCHASED_PRICE = 'purchasedPrice'
export const FIELD_WITH_SYMBOL = 'withSymbol'
export const FIELD_READ_ONLY = 'readOnly'
export const FIELD_NEGATIVE = 'negative'

export const DEALER_DOCUMENTATION_FEE_CODE = 'dealer.documentation.fee'
export const DEALER_DOCUMENTATION_FEE_DEFAULT_VALUE = '0.00'
export const SALES_REGISTRATION_FEES_CODE = 'sales.registration.fees'
export const SALES_REGISTRATION_FEES_TITLE = 'Registration Fee'
export const SALES_REGISTRATION_FEES_DEFAULT_VALUE = ''
export const SALES_NON_TAXABLE_STATE_INSPECTION_CODE = 'sales.non.taxable.state.inspection'
export const SALES_NON_TAXABLE_STATE_INSPECTION_TITLE = 'State Inspection/Emissions Test'
export const SALES_NON_TAXABLE_STATE_INSPECTION_DEFAULT_VALUE = ''
export const SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE = 'sales.non.taxable.temporary.permit'
export const SALES_NON_TAXABLE_TEMPORARY_PERMIT_TITLE = 'Temporary Permit'
export const SALES_NON_TAXABLE_TEMPORARY_PERMIT_DEFAULT_VALUE = ''
export const SALES_NON_TAXABLE_OTHERS_CODE = 'sales.non.taxable.others'
export const SALES_NON_TAXABLE_TITLING_FEE_CODE = 'sales.non.taxable.titling.fee'
export const SALES_NON_TAXABLE_TITLING_FEE_TITLE = 'Titling Fee'
export const SALES_NON_TAXABLE_TITLING_FEE_DEFAULT_VALUE = ''

export const MENU_SETTING_PERMISSION_ID = 'can_access_setting_menu'
export const ROLES_SETTING_PERMISSION_ID = 'can_access_roles_setting_menu'
export const USERS_SETTING_PERMISSION_ID = 'can_access_users_setting_menu'
export const COST_SECTION_PERMISSION_ID = 'can_access_cost_section_inventory'

export const ADMIN_DASHBOARD_PERMISSION_CODE = 'can_access_admin_dashboard'
export const SALES_DASHBOARD_PERMISSION_CODE = 'can_access_sales_dashboard'
export const TECH_DASHBOARD_PERMISSION_CODE = 'can_access_lot_tech_dashboard'

export const REVIEWS_DASHBOARD_PERMISSION_CODE = 'can_access_review_dashboard'

export const roles = {
  admin: 'admin',
  principal: 'principal',
  sales: 'Sales',
  accounting: 'accounting',
  lotTech: 'lot tech',
  mechanic: 'mechanic'
}

export const saleAttribute = {
  OTHER_TERMS: 'sale.otherTerms',
  AMOUNT_FINANCED: 'sale.amountFinanced'
}

export const LOGO = {
  FACEBOOK: 'facebook',
  KSL: 'ksl',
  ZOOM_AUTOS: 'zoom',
  GOOGLE: 'google',
  INSTAGRAM: 'instagram',
  FBMARKETPLACE: 'fbmarketplace',
  CARFAX: 'carfax',
}

export const CODE_NUMBER_PHONE_EEUU = '+1'

export const importKey = {
  CAR: 'uploadCars',
  CUSTOMER: 'uploadCustomers'
}

export const entityTypes = {
  CAR: 'car',
  CUSTOMER: 'customer'
}

export const fonts = [
  { key: 'san serif', value: 'San Serif' },
  { key: 'arial', value: 'Arial' },
  { key: 'arial Black', value: 'Arial Black' },
  { key: 'comic sans ms', value: 'Comic Sans MS' },
  { key: 'courier new', value: 'Courier New' },
  { key: 'impact', value: 'Impact' },
  { key: 'lucida console', value: 'Lucida Console' },
  { key: 'impact', value: 'Impact' },
  { key: 'lucida sans unicode', value: 'Lucida Sans Unicode' },
  { key: 'tahoma', value: 'Tahoma' },
  { key: 'times new roman', value: 'Times New Roman' },
  { key: 'trebuchet', value: 'Trebuchet MS' },
  { key: 'verdana', value: 'Verdana' },
]

export const validateMonths = {
  applicantType: 'individualCredit',
  applicantTypeJoin: 'joinCredit',
  firstResident: 'firstResident',
  secondResident: 'secondResident',
  firstEmployer: 'firstEmployer',
  secondEmployer: 'secondEmployer'
}

export const saleStaticFiles = {
  DRIVER_LICENSE: 'driverlicense',
  TAX_EXCEPTION: 'taxexemption',
  TEST_CODE: 'testcode'
}

export const responseStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

export const mediaTypes = {
  IMAGE: 'image',
  VIDEO: 'video'
}

export const SUBSECTION = {
  CURRENT: 'current',
  SECOND: 'second',
  THIRD: 'third',
}

export const ATTRIBUTES_FOR_SECOND_EMPLOYMENT = [
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_SECOND,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_SECOND,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_SECOND,
  ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_SECOND,
]
export const ATTRIBUTES_FOR_THIRD_EMPLOYMENT = [
  ATTRIBUTE_CUSTOMER_EMPLOYER_NAME_THIRD,
  ATTRIBUTE_CUSTOMER_JOB_TITLE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CELLPHONE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ADDRESS_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_CITY_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_ZIP_CODE_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_STATUS_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_YEAR_THIRD,
  ATTRIBUTE_CUSTOMER_EMPLOYER_MONTH_THIRD,
  ATTRIBUTE_CUSTOMER_MONTHLY_INCOME_THIRD,
]

export const CAR_FIELDS_NAME = {
  VIN: 'vin',
  STOCK_NUMBER: 'car.stockNumber',
  MILEAGE: 'mileage',
  TITLE_TYPE: 'car.title',
  LOCATION: 'locationId',
  MAKE: 'makeId',
  MODEL: 'modelId',
  YEAR: 'year',
  TRIM: 'car.trim',
  DOORS: 'car.numberDoor',
  CYLINDERS: 'car.cylinder',
  DRIVE: 'car.drive',
  TRANSMISSION: 'car.transmission',
  BODY_STYLE: 'car.body',
  FUEL: 'car.fuel',
  INTERIOR_COLOR: 'car.interiorColor',
  INTERIOR_CONDITION: 'car.interiorCondition',
  EXTERIOR_COLOR: 'car.exteriorColor',
  EXTERIOR_CONDITION: 'car.exteriorCondition',
  DESCRIPTION: 'description',
  PURCHASE_TYPE: 'car.purchaseType',
  TITLE_STATUS: 'car.status',
  PURCHASE_DATE: 'purchaseDate',
  TEMPORARY: 'temporary',
  CONDITION: 'car.condition',
}

export const dateFormats = {
  MM_DD_YYYY: 'MM/DD/YYYY',
  MM_DD_YYYY_HYPHEN: 'MM-DD-YYYY',
}

export const LINK_TO_PAGE = 'linkToPage'
export const LINK_TO_SECTION = 'linkToSection'
export const LINK_TO_URL = 'linkToUrl'

export const DEFAULT_SYSTEM_EMAIL_SUPPORT = 'support@godealergo.com'

export const FULL_BALANCE = 'fullBalance'
export const NEW_BALANCE = 'newBalance'

export const metadataTypes = {
  TITLE: 'title',
  KEYWORDS: 'keywords',
  DESCRIPTION: 'description'
}

export const SEO_CODES = {
  OPTIMIZE_SITE: 'seo.optimize.site.mobile.devices',
  ADD_CONTACT_DETAILS: 'seo.add.contact.details',
  CONNECT_SOCIAL_NETWORKS: 'seo.connect.social.networks',
  ADD_LINKS: 'seo.add.links',
};


export const siteColorAdmin = {
  boxBackground: '#FFF',
  boxBorder: '#D7DAE2',
  text: '#787878'
}

export const socialNetworks = {
  facebook: {
    icon: 'ic_facebook.svg',
    backgroundColor1: '#0264E1',
    backgroundColor2: '#14ACFE',
  },
  google:{
    icon: 'ic_google.svg',
    backgroundColor1: '#FF3D00',
    backgroundColor2: '#EA7C1E',
  },
};

export const FACEBOOK_KEY = 'facebook';
export const GOOGLE_KEY = 'google';

export const NEW_CUSTOMER_REVIEW_FORM = 'newCustomerReviewForm'

export const ENTTIY_TYPE = {
  DEALER: "DEALER",
  CUSTOMER_REVIEW: "CUSTOMER_REVIEWS",
}

export const reactions = {
  LIKE: 'LIKE',
  HAHA: 'HAHA',
  LOVE: 'LOVE',
  ANGRY: 'ANGRY',
  SAD: 'SAD',
  CARE: 'CARE',
  WOW: 'WOW'
}

export const paymentTypes = {
  SALE: 'sale',
  BILL: 'bill'
}

export const orderStatus = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  UNPAID: 'unpaid',
  PAID: 'paid'
}

export const GET_SOCIAL_NETWORK_PAGE = 'public/api/getSocialNetworkPage'

export const IS_LOGGED_IN_KEY = 'isLoggedIn'

export const MONTH_DAYS = 30
export const DUE_DAYS = 10
export const MAX_LENGTH_KEYWORDS = 45

export const NON_TAXABLE_ITEMS_REFERENCE_ORDER = [
  {code: SALES_REGISTRATION_FEES_CODE, description: ['registration fees', 'registration fee'],  newDescription: SALES_REGISTRATION_FEES_TITLE},
  {code: SALES_NON_TAXABLE_TITLING_FEE_CODE, description: ['titling fees', 'titling fee'], newDescription: SALES_NON_TAXABLE_TITLING_FEE_TITLE},
  {code: SALES_NON_TAXABLE_STATE_INSPECTION_CODE, description: ['state inspection/emissions test'], newDescription: SALES_NON_TAXABLE_STATE_INSPECTION_TITLE},
  {code: SALES_NON_TAXABLE_TEMPORARY_PERMIT_CODE, description: ['temporary permit fee', 'temporary permit fees', 'temporary permit'], newDescription: SALES_NON_TAXABLE_TEMPORARY_PERMIT_TITLE}
]

export const SALES_BALANCE_TOTAL_BOX_LABELS = {
  PRICE: 'Price',
  ACCESSORIES_SERVICES: 'Accessories + Services',
  REBATES: 'Rebates',
  TRADE_IN_CREDITS : 'Trade-in and Credits',
  STATE_TAX : 'State Tax',
  REGISTRATION_FEE: 'Registration Fees',
  BALANCE_DUE : 'Balance Due',
}

export const SALES_BALANCE_TITLE_LABELS = {
  PRICE: 'Price',
  ACCESSORIES_SERVICES: 'Accessories + Services',
  REBATES: 'Rebates' ,
  TRADE_IN: 'Trade-in and Credits',
  REGISTRATION_FEES:  'Registration fees and other non-taxable items',
}

export const SALES_BALANCE_ITEM_LABELS = {
  DEALER_DOCUMENTATION_FEE: 'Dealer Documentation Fee',
  TRADE_IN_ALLOWANCE : 'Trade-in Allowance',
  BALANCE_OWED_ON_TRADE_IN : 'Balance Owed on Trade-in',
  DEPOSIT_CASH_DOWN_PAYMENT: 'Deposit / Cash Down Payment',
  REGISTRATION_FEE: 'Registration Fee ',
  TITLING_FEE : 'Titling Fee',
  STATE_INSPECTION_EMISSIONS_TEST : 'State Inspection/Emissions Test',
  TEMPORARY_PERMIT : 'Temporary Permit',
}