/**
 * Created by johnny on 18/08/16
 */
import _ from 'lodash'
import React from 'react'
import {
  CAR_STATUS_ACQUIRED,
  CAR_STATUS_ARCHIVED,
  CAR_STATUS_CODE_FRONT_LINE_READY,
  CAR_STATUS_COMING_SOON,
  CAR_STATUS_FRONT_LINE_READY,
  CAR_STATUS_HOLD,
  CAR_STATUS_INSPECT_REPAIR,
  CAR_STATUS_SOLD,
  MODULE_COST,
  MODULE_USER,
  MODULE_VEHICLE,
  MONTH_DAYS,
} from '../../Constants'
import isEmpty from 'lodash/isEmpty'
import Moment from 'moment'
import {sortBy} from 'lodash/collection'
import {ReactSVG} from 'react-svg'
import {DPWarning} from '../../common/DPWarning'
import {getServerUrl} from '../../Api'
import {
  pathServer,
  VALIDATION_REQUIRED,
  VIEW_MOBILE,
  VIEW_TABLET,
  VIEW_WEB,
  WIDTH_VIEW_MOBILE,
  WIDTH_VIEW_TABLET
} from '../../../common/Constants'

export function groupByStatusCar(list , keyGetter){
  const map = new Map();
  const arr = [];
  if(keyGetter){
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    map.forEach(m => arr.push(m));
  } else{
    return list;
  }
  return arr;
}

export function getFilterCar(data){
  let filter = { "value" : '' , "id" : '' , "color": ''};
  switch (data) {
    case CAR_STATUS_FRONT_LINE_READY:
      filter['value'] =  CAR_STATUS_FRONT_LINE_READY;
      filter['color'] = "#4AD991";
      filter['id'] = 'frontLineReady'
      break;
    case CAR_STATUS_ACQUIRED:
      filter['value'] =  CAR_STATUS_ACQUIRED;
      filter['color'] = "#AC7F62";
      filter['id'] = 'acquired'
      break;
    case CAR_STATUS_COMING_SOON:
      filter['value'] =  CAR_STATUS_COMING_SOON;
      filter['color'] = "#F3A200";
      filter['id'] = 'comingSoon'
      break;
    case CAR_STATUS_INSPECT_REPAIR:
      filter['value'] =  CAR_STATUS_INSPECT_REPAIR;
      filter['color'] = "#2194F1";
      filter['id'] = 'inspectRepair'
      break;
    case CAR_STATUS_HOLD:
      filter['value'] =  CAR_STATUS_HOLD;
      filter['color'] = "#005294";
      filter['id'] = 'hold'
      break;
    case CAR_STATUS_SOLD:
      filter['value'] =  CAR_STATUS_SOLD;
      filter['color'] = "#FF6565";
      filter['id'] = 'sold'
      break;
    case CAR_STATUS_ARCHIVED:
      filter['value'] =  CAR_STATUS_ARCHIVED;
      filter['color'] = "#787878";
      filter['id'] = 'archived'
      break;
    default :
      filter['value'] =  CAR_STATUS_CODE_FRONT_LINE_READY;
      filter['color'] = "#4AD991";
      filter['id'] = 'frontLineReady'
  }
  return filter;
}

export function deleteItemArray(id , arr){
  const index = arr.findIndex( a => a.id === id);
  if(index > -1){
    arr.splice(index,1);
  }
  return arr;
}

export function formatDecimal(num) {
  if(num && !isNaN(num)){
    let p = num.toFixed(2).split(".");
    return p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return  num + (i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
  }
}

export function isYear(value) {
  return (value.match(/^[0-9]{4}$/) !== null);
}

export function isFloat(value) {
  return (value.match(/^[0-9]*.?[0-9]+$/) !== null);
}

export function setErrors(attributes,section,errors){
  for(let key in section){
    if(section[key] === "" || (_.isArray(section[key]) && section[key].length === 0)){
      let attributeFound = _.find(attributes,function (attribute) { return attribute.id === key});
      if(attributeFound.validation.length > 0){
        _.forEach(attributeFound.validation, function (validation) {
          if(validation.type === VALIDATION_REQUIRED){
            errors[key] = [];
            errors[key].push(validation.type);
          }
        });
      }
    }
  }
}

export function setPlaceHolder(id,displayValue){
  let item = document.getElementById(id);

  if(item)
    item.placeholder = displayValue;
}

export function getLabelWithStyle(label,keyword,style) {
  const displayValue = [];
  if(keyword && keyword !== ""){
    const indexFound = label.toLowerCase().indexOf(keyword.toLowerCase());
    let wordFirst = "";
    let wordFound = "";
    let wordLast  = "";
    const length = keyword.length;
    if(indexFound === 0){
      wordFound = label.substr(indexFound,length);
      wordLast  = label.substr(length);
    }else if(indexFound === (label.length - 1)){
      wordFirst = label.substr(0,indexFound);
      wordFound = label.substr(indexFound,length);
    }else{
      wordFirst = label.substr(0,indexFound);
      wordFound = label.substr(indexFound,length);
      wordLast  = label.substring(indexFound + length);
    }

    displayValue.push(<span key={keyword+1}>{wordFirst}</span>);
    displayValue.push(<span key={keyword+2} style={style}>{wordFound}</span>);
    displayValue.push(<span key={keyword+3}>{wordLast}</span>);
  }else{
    displayValue.push(<span key={keyword}>{label}</span>);
  }

  return displayValue;
}

export function toUrlFormEncoded(params) {
  //return _.map(params, (value, key) => key + '=' + encodeURIComponent(value)).join('&');
  let url = '';
  for(let key in params){
    if(params[key] !== '' && params[key] !== undefined && params[key] !== null){
      if(url !== '')
        url += `&${key}=${encodeURIComponent(params[key])}`;
      else
        url = `?${key}=${encodeURIComponent(params[key])}`;
    }
  }

  return url;
}

export function capitalFirstLetter(word){
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function calculateHeightDifference(headerHeight, paddingTopBottom) {
  return headerHeight + 2 * paddingTopBottom
}

export function validateError(error) {
  let hasError = false;
  for (let key in error) {
    if (error[key]) {
      hasError = true;
      return hasError;
    }
  }
  return hasError
}

export function getDynamicNumberValue(modelValue,viewValue) {
  let value = modelValue;
  if(modelValue.toString() !== viewValue || modelValue === 0)
    value = viewValue;
  return value;
}

export function parseDynamicNumberValue(value) {
  return value ? parseFloat(value.toString().replace(',','')) : 0;
}

export function roundToTwoDecimals(value){
  return Math.round(value*100)/100
}

export function getComputedStyleById(id,property) {
  const element = document.getElementById(id);
  if(!element){
    return null;
  }
  const compStyles = window.getComputedStyle(element);
  const result = compStyles.getPropertyValue(property);
  return result;
}

export function setFormatDate(value) {
  let formatDate = "YYYY-MM-DD";
  let  dateObject = {};
  if (value && !isEmpty(value)) {
    const valueArray = value.split("-");

    if(valueArray[0].length === 2){
      formatDate = "MM-DD-YYYY"
    }
    const valueDate = Moment(value,formatDate);
    dateObject = new Date(valueDate);
  }
  return dateObject
}
export function getInputValueString(value) {
  return value ? value : ""
}

export function getValueOrDash(value, isMoneyType=false) {
  return value ? ( isMoneyType ? `$ ${value}.00` : value )  : "-"
}

export function downloadFile(file, evt) {
  evt.stopPropagation();
  let fileURL = file.url;

  /* let values = file.name.split(".");
  let typeFile = (values[values.length - 1]).toLowerCase();
  let contentType = "";
  if (typeFile === EXT_PDF){
    contentType = CONTENT_TYPE_PDF;
  } else if (typeFile === EXT_WORD){
    contentType = CONTENT_TYPE_WORD;
  } else if (typeFile === EXT_IMG || typeFile === EXT_OTHER_IMG){
    contentType = CONTENT_TYPE_IMAGE;
  }
  let oReq = new XMLHttpRequest();
  oReq.open("GET", URLToPDF, true);
  oReq.responseType = "blob";
  oReq.onload = function() {
    let file = new Blob([oReq.response], {
      type: contentType
    });
    FileSaver.saveAs(file, document.name);
  };
  oReq.send();*/

  const link = document.createElement('a');
  link.href = fileURL;
  link.download = file.name;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const fileStyles = {
  pdf: {
    textColor: '#FF6565',
    iconColor: '#FF0A0A',
    backColor: '#FFE3E3',
    icon: 'ic_pdf.svg'
  },
  docx: {
    textColor: '#4F7CFF',
    iconColor: '#4F7CFF',
    backColor: '#EDF1FF',
    icon: 'ic_description.svg'
  },
  jpg: {
    textColor: '#005294',
    iconColor: '#005294',
    backColor: '#D2E4FF',
    icon: 'ic_photo.svg'
  },

  jpeg: {
    textColor: '#005294',
    iconColor: '#005294',
    backColor: '#D2E4FF',
    icon: 'ic_photo.svg'
  }
}

export function parseValueToDynamicNumber(value) {
  return value ? parseFloat(value.toString().replace(',','')) : '';
}

export function getOptionsSelectDropdown(options, idKey, displayValueKey) {
  if(!options || options.length <= 0) return 0

  return options.map(option => ({"id": option[idKey], "displayValue": option[displayValueKey]}))
}

export function parseDynamicNumberToNumber(value) {
  return value ? parseInt(value) : '';
}

export function isEmptyObject(value) {
  return Object.keys(value).length === 0 && value.constructor === Object;
}

export function fromObjectToArrayOfObjects(obj){
  return isEmptyObject(obj) ? [] : Object.entries(obj).map( entry =>({[entry[0]]: entry[1]}))
}

export function fromArrayOfObjectsToObject(arrayOfObjects){
  let obj = {}
  if(!arrayOfObjects) return {}

  arrayOfObjects.forEach( o => {
    const key = Object.keys(o)[0]
    obj[key] = o[Object.keys(o)[0]]
  })

  return obj
}

export function onSortMessage(newMessages, index) {
  let formSort = sortBy(newMessages, [function (o) {
    return o["createDate"];
  }]);
  if (index === "newest") {
    formSort.reverse();
  }
  return formSort;
}

export function units(num){

  switch(num)
  {
    case 1: return 'one';
    case 2: return 'two';
    case 3: return 'three';
    case 4: return 'four';
    case 5: return 'five';
    case 6: return 'six';
    case 7: return 'seven';
    case 8: return 'eight';
    case 9: return 'nine';
  }

  return '';
}

export const formatCardNumber = value => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
  const onlyNumbers = value.replace(/[^\d]/g, '')
  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter(group => !!group).join('-')
  )
}

export function loadScripts(urls) {
  let scriptLoadedNum = 0

  return new Promise((resolve, reject) => {
    urls.forEach((url) => {
      const script = document.createElement("script")
      script.src = url
      script.onload = () => {
        console.log("script loaded")
        scriptLoadedNum++

        if (scriptLoadedNum === urls.length) {
          resolve(urls)
        }
      }
      document.body.appendChild(script)
    })
  })
}

export function getFormatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const getPositionById = (id, addHeight = false) =>{
  let target = document.getElementById(id);
  if(target){
    return {
      x: target.offsetLeft,
      y: addHeight ? target.offsetTop + target.offsetHeight + 2 : target.offsetTop,
      offsetWidth: target.offsetWidth
    }
  }else{
    return {}
  }
}

export function getAnchorEl(target, separator, width) {
  return {
    x: target.offsetLeft,
    y: target.offsetTop + target.offsetHeight + separator,
    offsetWidth: width && width > 0 ? width : target.offsetWidth
  }
}

export function textWithoutHTML(textHTML){
  let div = document.createElement("div");
  div.innerHTML = textHTML;
  return div.textContent || div.innerText || "";
}

export function formatToNumber(field) {
  if ( typeof field == 'number') {
    return field;
  } else {
    return Number(field.replace(',', ''));
  }
}

export function cleanArray(array){
  while (array.length > 0) {
    array.pop();
  }
}

export function  getWidthColumn(column){
  let width = "";
  switch(column){
    case 1 :
      width = "col100";
      break;
    case 2 :
      width = "col50";
      break;
    case 3 :
      width = "col33";
      break;
    case 4 :
      width = "col25";
      break;
    case 5 :
      width = "col20";
      break;
    case 6 :
      width = "col16";
      break;
    case 7 :
      width = "col14";
      break;
    case 8 :
      width = "col12";
      break;
    case 9 :
      width = "col9";
      break;
    case 10 :
      width = "col10";
      break;
  }

  return width
}

export function moveElement(statusList,status){
  let lst = [];
  statusList.forEach(el =>{
    if(el !== status)
      lst.push(el);
  });
  return lst;
}

export function getAttributeJSONFormBuilder(attributeDB) {
  let attribute = {
    id: attributeDB.id,
    name: attributeDB.code || attributeDB.name,
    displayValue: attributeDB.displayValue,
    inputType: attributeDB.typeAttribute || attributeDB.inputType,
    //validation: [],
    //hasParentChild: false,
    //parentIds: [],
    attributeValues: attributeDB.attributeValues
  };

  if(attributeDB.maxLength)
    attribute.maxLength = attributeDB.maxLength;

  if(attributeDB.validation)
    attribute.validation = attributeDB.validation;

  if(attributeDB.hasParentChild !== 'undefined')
    attribute.hasParentChild = attributeDB.hasParentChild;

  if(attributeDB.parentIds)
    attribute.parentIds = attributeDB.parentIds;

  if(attributeDB.withSymbol !== 'undefined')
    attribute.withSymbol = attributeDB.withSymbol;

  if(attributeDB.readOnly !== 'undefined')
    attribute.readOnly = attributeDB.readOnly;

  return attribute
}

export function maskEmail(email) {
  let maskId = "";
  let prefix= email.substring(0, email .lastIndexOf("@"));
  let postfix= email.substring(email .lastIndexOf("@"));
  for(let i=0; i<prefix.length; i++){
    if(i === 0 || i === prefix.length - 1) {
      maskId = maskId + prefix[i].toString();
    }
    else {
      maskId = maskId + "*";
    }
  }
  maskId =maskId +postfix;
  return maskId;
}

export function getFileNameAndType(fileName, maxLength) {
  const values = fileName.split(".");
  const name = values[0].substring(0, maxLength ? maxLength : 14);
  return {name:name, type:values[values.length - 1].toLowerCase()};
}

export function getSubString(value,maxLength) {
  if(value.length > maxLength){
    return `${value.substring(0,maxLength - 1)}.`
  }
  return value;
}

export function convertListToString(values) {
  let valueString = "";
  if(values && values.length > 0){
    valueString = values.map((value) => {return value}).join(',');
  }
  return valueString;
}

export function getView(width){
  let view = VIEW_WEB;
  if (width <= WIDTH_VIEW_TABLET && width > WIDTH_VIEW_MOBILE) {
    view = VIEW_TABLET;
  } else if (width <= WIDTH_VIEW_MOBILE) {
    view = VIEW_MOBILE;
  }
  return view
}

export function modalObjectToShow(isUser, isVehicle, isCost){
  if(isUser) return MODULE_USER
  else if(isVehicle) return MODULE_VEHICLE
  else if(isCost) return MODULE_COST
}

export function formatMoney(value){
  return Number(String(value).replace(/[^0-9.-]+/g,""))
}

export function mergeArrays(...arrays) {
  let jointArray = []
  arrays.forEach(array => {
    jointArray = [...jointArray, ...array]
  });
  return [...new Set([...jointArray])]
}

export function modalChangeCarStatus(actualStatus, newStatus, saleId=null, repairId=null){
  let header = ''

  if(!!saleId)
    header = "You can't update this vehicle because it has a sale attached."
  else if(!!repairId)
    header = "Status updated."
  else
    header = "Updating..."

  return(
    <div>
      <div className="modal-update-status-header" style={!!saleId || !!repairId ? { marginTop:"-20px" }:null}>
        { header }
      </div>
      <div className="modal-update-status-boxes">
        <div className="box sold-box">{actualStatus}</div>
        <ReactSVG src={pathServer.PATH_IMG + "icon/ic_arrow_next.svg"} beforeInjection={svg => {
          svg.classList.add('icon-arrow')
          svg.setAttribute('style', `fill: #D7DAE2`)
        }}
        />
        <div className="box new-state-box">{newStatus}</div>
      </div>
      {
        !!saleId &&
        <div className="has-sale-message">
          <DPWarning message="You can deactivate this vehicle and create a new one."/>
        </div>
      }
      {
        !!repairId &&
        <div className="has-sale-message">
          <DPWarning message={
            <label><a href={`${getServerUrl()}main/task/${repairId}`} target="_blank">A new repair task</a> has been created</label>
          }/>
        </div>
      }
    </div>
  )
}

function buildTableHeader(columns){
  return (
    <thead>
    <tr>
      {
        columns.map((title, index) => <th key={index}>{title}</th>)
      }
    </tr>
    </thead>
  )
}

function formatDollar(num) {
  if(num){
    let p = num.toFixed(2).split(".");
    return "$" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return  num + (i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
  }else{
    return "$ 0.00";
  }
}

function buildTableBody(rows, reportMetadata){

  function applyFormatToValues(value, formatType){
    switch (formatType){
      case "Currency": return formatDollar(parseFloat(value))
      case "Text": return capitalFirstLetter(value)
      case "Date": return value !== null ? Moment(value).format('MM/DD/YYYY HH:mm') : "None"
      case "ShortDate": return value !== null ? Moment(value).format('MM/DD/YYYY') : "None"
      default: return value
    }
  }

  function bodyRow(row, key){
    return(
      <tr key={`${key}-row`}>
        {row.map((value, idx) => {
          return <td key={`${idx}-${value}`}>{applyFormatToValues(value, reportMetadata[idx].type)}</td>
        })}
      </tr>
    )
  }

  return (
    <tbody>
    {
      rows.map(bodyRow, reportMetadata)
    }
    </tbody>
  )
}

export function visualizationTableBuilder(reportVisualization, reportMetadata){
  const columns = reportVisualization.visualization?.columns
  const rows = reportVisualization.visualization?.rows

  if(!columns.length && !rows.length){
    return <p className="empty-visualization">No rows found.</p>
  }else {
    return(
      <section id="visualization-wrapper">
        <table>
          {buildTableHeader(columns)}
          {buildTableBody(rows, reportMetadata)}
        </table>
      </section>
    )
  }
}

export function getDashboardId(dashboards){
  return dashboards[0]?.dashboard?.id
}

export function getImageUrlWithTime(imageUrl) {
  if (imageUrl.substring(0, 3) === 'http') {
    const timeStamp = (new Date()).getTime()
    return (`${imageUrl}?_=${timeStamp}`)
  }

  return imageUrl
}

export function cleanUpBraintree(instance) {
  if (instance) {
    instance.teardown(function (teardownErr) {
      if (teardownErr) {
        console.error('Could not tear down the Hosted Fields form!')
      } else {
        console.info('Hosted Fields form has been torn down!')
      }
    })
  }
}

export function getNewDate(date) {
  const year = date.get('year');
  const month = date.get('month');  // 0 to 11
  const day = date.get('date');
  const hour = date.get('hour');
  const minute = date.get('minute');
  const second = date.get('second');
  const miliSecond = date.get('millisecond');

  return new Date(year, month, day, hour, minute, second, miliSecond)
}

export function roundDayDurationBtwDates(startDate, endDate) {
  const daysDuration = endDate.diff(startDate, 'days')
  const modulus = daysDuration % MONTH_DAYS
  const days = daysDuration - modulus

  return (modulus > 0 ? days + MONTH_DAYS : days)
}

export function formatDateToString(dateToBeFormatted) {
  if (dateToBeFormatted === null) {
    return ''
  }

  return Moment(dateToBeFormatted).format("MM/DD/YYYY")
}

export const getErrorsInCosts = (costs = []) => {
  const costWithErrors = costs.filter( ({part, labor, description}) => isEmpty(description) || (isEmpty(part) && isEmpty(labor)) )
  return costWithErrors.map(({part, labor, description, id, temporalId}) => {
    const partLaborError = (isEmpty(part) && isEmpty(labor)) ? {part: isEmpty(part), labor: isEmpty(labor)} : {}
    return { id: id ?? temporalId, description: isEmpty(description), ...partLaborError,}
  })
}

export const calculateStateTax = (carPrice, accessoryServiceTotal, rebatesTotal, tradeInAllowance, tax) => {
  /**** stateTax = (carPrice + accessoryServiceTotal - rebatesTotal - tradeInAllowance) * tax ****/
  const stateTaxValue = (parseDynamicNumberValue(carPrice) + parseDynamicNumberValue(accessoryServiceTotal) - parseDynamicNumberValue(rebatesTotal)  - parseDynamicNumberValue(tradeInAllowance))*tax
  return (stateTaxValue > 0) ? stateTaxValue : 0
}

export const sortByReferenceOrderList = (unsortedArray, referenceOrder) => {
  // Updating descriptions
  unsortedArray.forEach(item => {
    const referenceItem = referenceOrder.find(ref => ref.description.includes(item.description.toLowerCase()) || ref.code === item.code);

    if (referenceItem) {
      item.description = referenceItem.newDescription;
      item.deleteDisabled = true;
    } else {
      item.deleteDisabled = false;
    }
  });

  // Filter items which appear in ReferenceOrder List
  const itemsInReferenceOrder = unsortedArray.filter(item =>
      referenceOrder.some(refItem => refItem.newDescription === item.description)
  );

  // Filter items which don't appear in ReferenceOrder List
  const itemsNotInReferenceOrder = unsortedArray.filter(item =>
      !referenceOrder.some(refItem => refItem.newDescription === item.description)
  );

  // Sort list of items that appear in ReferenceOrder List
  itemsInReferenceOrder.sort((a, b) => {
    const indexA = referenceOrder.findIndex(item => item.newDescription === a.description);
    const indexB = referenceOrder.findIndex(item => item.newDescription === b.description);
    return indexA - indexB;
  });

  return ([...itemsInReferenceOrder, ...itemsNotInReferenceOrder]);
}